import { IconName } from '@leon-hub/icons';

import type { ConfirmDialog } from '@core/dialogs';
import type { I18nFunctionReactive } from '@core/i18n';
import { DialogAction } from '@core/dialogs';

import { ModalWidth } from '@components/dialogs';
import { JumbotronIconKind } from '@components/jumbotron';

export default function getModalBonusProperties(
  $t: I18nFunctionReactive,
): Partial<ConfirmDialog> {
  return {
    iconName: IconName.CHECK_OUTLINE,
    iconKind: JumbotronIconKind.SUCCESS,
    width: ModalWidth.SMALL,
    title: $t('WEB2_BONUS_OUTPUT_SUCCESS').value,
    buttons: [
      {
        label: $t('JSP_PCL_FBOT_CLOSE').value,
        action: DialogAction.MODAL_CLOSE,
      },
    ],
  };
}
