import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { Bonus } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useFormatMoney } from '@core/money';

import { useBonusStore } from 'web/src/modules/bonuses/store';

import type { CasinoGameBonusContainerEmits } from '../types';

export interface CasinoGameBonusContainerComposable {
  isLoggedIn: Ref<boolean>;
  bonus: Ref<Bonus | undefined>;
  withdrawMoney: Ref<string>;

  getBonuses(): Promise<void>;

  onWithdrawBonus(withdrawalFunction: (callback: () => (void | Promise<void>)) => void): void;

  redirectToBonusDetails(item: Bonus): Promise<void>;
}

export default function useCasinoGameBonusContainer(
  emit: CasinoGameBonusContainerEmits,
): CasinoGameBonusContainerComposable {
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const formatMoney = useFormatMoney();
  const bonusStore = useBonusStore();

  const bonus = toRef(() => bonusStore.bonusCbc);

  const withdrawMoney = computed(() => {
    if (!bonus.value) {
      return '';
    }
    const { walletBalanceNumber, currency } = bonus.value;
    return formatMoney(walletBalanceNumber, { currency });
  });

  async function onWithdrawBonusCallback(): Promise<void> {
    await bonusStore.doWithdrawBonusCBC();
    emit('withdraw-bonus');
    await bonusStore.fetchBonusWagers();
  }

  function onWithdrawBonus(withdrawalFunction: (callback: () => (void | Promise<void>)) => void): void {
    withdrawalFunction(onWithdrawBonusCallback);
  }

  async function redirectToBonusDetails(item: Bonus): Promise<void> {
    await router.push({
      name: RouteName.PROMOTION_DETAILS,
      params: {
        categoryId: item?.categoryId?.toLowerCase() ?? item.category.toLowerCase(),
        actionUrl: item.actionUrl,
      },
    });
  }

  return {
    isLoggedIn,
    withdrawMoney,
    bonus,
    getBonuses: bonusStore.fetchBonusWagers,
    onWithdrawBonus,
    redirectToBonusDetails,
  };
}
