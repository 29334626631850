import type { ShowDialogOptions } from '@core/dialogs';
import type { I18nFunctionReactive } from '@core/i18n';
import { PresetName } from '@core/dialogs';

export default function getModalErrorHandlerProperties(
  message: string,
  $t: I18nFunctionReactive,
): ShowDialogOptions {
  return {
    presetName: PresetName.ALERT_WARNING,
    options: {
      confirmMessage: message,
      title: $t('WEB2_DEPOSIT_ERROR').value,
    },
  };
}
