import type { Ref } from 'vue';
import { computed, onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';
import { CircleSize } from '@components/circular-progress';

import type { BonusPointsContainerProps } from 'web/src/modules/bonuses/components/BonusPointsContainer/types';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';

export interface BonusPointsContainerComposable {
  loyaltyProgramEgsStatus: Ref<boolean | undefined>;
  bonusLoaded: Ref<boolean>;
  openBonusLoyaltyPage(): void;
  progressProperties: Ref<{ percentage: number; circleSize: CircleSize }>;
  pointsLabelProperties: Ref<string>;
  progress: Ref<number>;
  requiredAmount: Ref<string>;
  buttonProperties: Ref<VButtonProps>;
  handleExchange(): void;
}

export default function useBonusPointsContainer(
  props: BonusPointsContainerProps,
): BonusPointsContainerComposable {
  const bonusLoyaltyStore = useBonusLoyaltyStore();
  const siteConfigStore = useSiteConfigStore();
  const formatMoney = useFormatMoney();
  const { $translate } = useI18n();
  const router = useRouter();

  const { isLoggedIn } = useIsLoggedIn();

  const loyaltyProgramEgsStatus = toRef(siteConfigStore, 'loyaltyProgramEgsStatus');

  const bonusLoaded = toRef(bonusLoyaltyStore, 'bonusLoaded');
  const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
  const remainingAmountNumber = toRef(bonusLoyaltyStore, 'remainingAmountNumber');
  const progress = toRef(bonusLoyaltyStore, 'progress');
  const progressPercentString = toRef(bonusLoyaltyStore, 'progressPercentString');

  const progressProperties = computed(() => ({
    percentage: progress.value > 0 ? Number.parseInt(progressPercentString.value, 10) : 0,
    circleSize: props.isSmall ? CircleSize.SIZE_32 : CircleSize.SIZE_40,
  }));
  const requiredAmount = computed(() => formatMoney(remainingAmountNumber.value, { currency: 'L' }));
  const pointsLabelProperties = computed(() => formatMoney(amountNumber.value || 0, { currency: 'L', hideCurrency: true }));
  const buttonProperties = computed(() => ({
    label: $translate('WEB2_JSPACC_ACC_EXCHANGE').value,
    kind: ButtonKind.SECONDARY,
    height: props.isSmall ? ButtonHeight.TINY : ButtonHeight.MEDIUM,
  }));

  const openBonusLoyaltyPage = () => {
    let name = RouteName.BONUS_WITHDRAWALS;
    if (progress.value < 1) {
      name = RouteName.LOYALTY_PROGRAM;
    }
    void router.push({ name });
  };

  const handleExchange = () => {
    void router.push({ name: RouteName.BONUS_WITHDRAWALS });
  };

  onMounted(async () => {
    if (isLoggedIn.value) {
      await bonusLoyaltyStore.fetchBonusLoyalty();
    }
  });

  return {
    loyaltyProgramEgsStatus,
    bonusLoaded,
    openBonusLoyaltyPage,
    progressProperties,
    pointsLabelProperties,
    progress,
    requiredAmount,
    buttonProperties,
    handleExchange,
  };
}
