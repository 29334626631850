<script setup lang="ts">
import { computed } from 'vue';

import { useFormatMoney } from '@core/money';

import type { PromotionCashbackWidgetEmits, PromotionCashbackWidgetProps }
  from 'web/src/modules/promotions/components/PromotionCashbackWidget/types';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';

const props = defineProps<PromotionCashbackWidgetProps>();
const emit = defineEmits<PromotionCashbackWidgetEmits>();
const formatMoney = useFormatMoney();
const cashbackAmountFormatted = computed(() => formatMoney(props.cashbackAmount));
</script>

<template>
  <div v-auto-id="'PromotionsCashbackWidget'" :class="$style['promotion-cashback-widget']">
    <div>
      <p :class="$style['promotion-cashback-widget__label']">
        {{ $t('WEB2_CURRENT_CASHBACK') }}
      </p>
      <span :class="$style['promotion-cashback-widget__amount']">
        {{ cashbackAmountFormatted }}
      </span>
    </div>
    <PromotionCountdown
      hidden-title
      is-alt-time-name
      is-cashback
      :timestamp="timestamp"
      @send="emit('send')"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .promotion-cashback-widget {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: if($isDesktop, 8px 16px, 12px);
    background-color: if($isDesktop, transparent, var(--Layer1));
    border-radius: if($isDesktop, 0 0 5px 5px, 5px);

    @include for-hover {
      &:hover {
        cursor: pointer;
      }
    }

    &__label {
      @include medium\14\16\025;

      margin: 0 0 4px;
      color: var(--TextDefault);
    }

    &__amount {
      @include medium\16\20\025;

      color: var(--BrandDefault);
    }
  }
}
</style>
