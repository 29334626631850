import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useSiteConfigStore } from '@core/site-config';

import { useCashbackStore } from 'web/src/modules/promotions/store';

export interface CasinoGameCashbackContainer {
  isVisible: Ref<boolean>;
  cashbackAmount: Ref<number>;
  timestamp: Ref<number>;

  onBalanceClick(): void;

  hideWidget(): void;
}

export default function useCasinoGameCashbackContainer(): CasinoGameCashbackContainer {
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();

  const isWeb2BonusCashbackWidgetEnabled = toRef(useSiteConfigStore(), 'isWeb2BonusCashbackWidgetEnabled');
  const cashbackStore = useCashbackStore();
  const cashbackAmount = toRef(cashbackStore, 'cashbackAmount');
  const timestamp = toRef(cashbackStore, 'timestampEndDate');
  const isCurrentCashback = toRef(cashbackStore, 'isCurrentCashback');
  const cashbackPromotionUrl = toRef(cashbackStore, 'cashbackPromotionUrl');
  const cashbackPromotionCategoryId = toRef(cashbackStore, 'cashbackPromotionCategoryId');

  const isVisible = computed(
    () => isLoggedIn.value && isWeb2BonusCashbackWidgetEnabled.value && isCurrentCashback.value,
  );

  function onBalanceClick(): void {
    if (cashbackPromotionCategoryId.value && cashbackPromotionUrl.value) {
      void router.push({
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId: cashbackPromotionCategoryId.value,
          actionUrl: cashbackPromotionUrl.value,
        },
      });
    }
  }

  function hideWidget(): void {
    cashbackStore.setCashbackActive(false);
    void cashbackStore.getCashback();
  }

  onBeforeMount(() => {
    if (isLoggedIn.value && isWeb2BonusCashbackWidgetEnabled.value) {
      void cashbackStore.getCashback();
    }
  });

  return {
    isVisible,
    cashbackAmount,
    timestamp,
    onBalanceClick,
    hideWidget,
  };
}
