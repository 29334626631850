<script lang="ts" setup>
import { computed } from 'vue';

import WithdrawalContainer from 'web/src/modules/bonuses/components/WithdrawalContainer/WithdrawalContainer.vue';
import CasinoGameBonusBar
  from 'web/src/modules/casino/submodules/game/components/CasinoGameBonusBar/CasinoGameBonusBar.vue';
import CasinoGameBonusPanel
  from 'web/src/modules/casino/submodules/game/components/CasinoGameBonusPanel/CasinoGameBonusPanel.vue';
import SCasinoGameBonusBar
  from 'web/src/modules/casino/submodules/game/components/SCasinoGameBonusBar/SCasinoGameBonusBar.vue';
import { PanelLayout } from 'web/src/modules/casino/submodules/game/enums';

import type { CasinoGameBonusContainerEmits, CasinoGameBonusContainerProps } from './types';
import { useCasinoGameBonusContainer } from './composables';

const props = withDefaults(defineProps<CasinoGameBonusContainerProps>(), {
  layout: PanelLayout.SLOTS_BAR,
});
const emit = defineEmits<CasinoGameBonusContainerEmits>();

const bonusComponent = computed(() => {
  switch (props.layout) {
    case PanelLayout.SLOTS_PANEL:
      return CasinoGameBonusPanel;
    case PanelLayout.SLOTS_BAR:
    default:
      return null ? SCasinoGameBonusBar : CasinoGameBonusBar;
  }
});

const {
  isLoggedIn,
  bonus,
  withdrawMoney,
  getBonuses,
  onWithdrawBonus,
  redirectToBonusDetails,
} = useCasinoGameBonusContainer(emit);
</script>

<template>
  <WithdrawalContainer v-auto-id="'CasinoGameBonusContainer'"
    v-if="isLoggedIn && bonus"
    :money="withdrawMoney"
    @modal-close="getBonuses"
  >
    <template #default="{ withdrawBonus }">
      <component
        :is="bonusComponent"
        :item="bonus"
        :is-clickable="isClickable"
        :is-narrowed="isNarrowed"
        @withdraw-bonus="onWithdrawBonus(withdrawBonus)"
        @click-bonus-details="redirectToBonusDetails"
      />
    </template>
  </WithdrawalContainer>
</template>
