import { computed } from 'vue';

import { AlertIconName, IconName } from '@leon-hub/icons';

import type { ConfirmDialog } from '@core/dialogs';
import type { I18nFunctionReactive } from '@core/i18n';
import { DialogAction } from '@core/dialogs';

import { ButtonKind } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';

import type { WithdrawalContainerProps } from 'web/src/modules/bonuses/components/WithdrawalContainer/types';
import { getImageOrIcon } from 'web/src/modules/icons';

export default function getModalConfirmProperties(
  props: WithdrawalContainerProps,
  $t: I18nFunctionReactive,
): Partial<ConfirmDialog> {
  const params = computed(() => ({
    AMOUNT: props.money || '',
  }));

  const { iconName, image } = getImageOrIcon({ alertIcon: AlertIconName.Bonus, icon: IconName.INFO_OUTLINE });
  return {
    iconName,
    image,
    width: ModalWidth.SMALL,
    title: $t('WEB2_BONUS_OUTPUT').value,
    confirmMessage: $t('WEB2_BONUS_OUTPUT_QUESTION', params).value,
    buttons: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      ? [
          {
            action: DialogAction.CONFIRM,
            kind: 'primary',
            label: $t('WEB2_MODAL_CONFIRM').value,
          },
          {
            action: DialogAction.MODAL_CLOSE,
            label: $t('WEB2_MODAL_CANCEL').value,
            kind: 'tertiary-primary',
          },
        ]
      : [
          {
            action: DialogAction.CONFIRM,
            kind: ButtonKind.PRIMARY,
            label: $t('WEB2_MODAL_CONFIRM').value,
          },
        ],
  };
}
